.Projects {
    height: 50vh;
    width: 100%;
  }
  
  .Project_slider {
    border-radius: 25px;
    margin-top: 5%;
    background-color: rgb(119, 151, 156);
    height:60%;
    width: 100%;
    
  }
  
  .panel {
    width: 88%;
    margin: auto;
    font-size: 1.9vh;
  }