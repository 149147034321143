.exp_panel {
    border-radius: 25px;
    margin: 5% 0;
    background-color: rgb(119, 151, 156);
    height: 33vh;
    width: 100%;
  }
  
  .experience{
    width: 100%;
  }
  
  .exp_sub_panel {
    height:80%;
    font-size: 1.9vh;
    width:auto;
  }

  .content {
    width: 90%;
    height: 100%;
    margin: auto;
  }