@import "~bootstrap/scss/bootstrap";

.App {
  background-color: #282c34;
  min-height: 100vh;
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
  text-align: center;
}

#root {
  background-color: #282c34;
}

body {
  background-color: #282c34;
}

.Skills{
  width: 80%;
}

.div{
  display: block;
}

.carousel {
  height: 100%;
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

ul {
  list-style-type: none;
  padding-inline-start: 0;
  margin-block-start: .3em;
  margin-block-end: .3em;
}

h4 {
  display: inline-block;
  margin-block-start: .3em;
  margin-block-end: .3em;
  color: rgb(119, 151, 156);
}



.active-carousel-item {
  align-items: center;
  justify-content: center;
}

.link {
  position: relative;
  z-index: 2;
}

.carousel-control-next, .carousel-control-prev {
  width: 5%;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.left {
  float: left;
  width: 50%;
}

.right {
  float: left;
  width: 50%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.images{
  height: 50vh;
}

.img{
  
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.carousel-inner{
  height: 100%;
  width: 100%;
}